<template>
  <ui-component-modal
    modalTitle="Edit languages"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveLocationLanguages"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped is-hoverable">
        <tbody>
          <tr v-for="(language, index) in languages" :key="index">
            <td>{{ language.Name }}</td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': languageSelected(language.Id) }">
                    <a @click="addLanguage(language.Id)">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': !languageSelected(language.Id) }">
                    <a @click="removeLanguage(language.Id)">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import locationProvider from '@/providers/location'

export default {
  components: {
    ...mapState('locationStore', ['locationState']),
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      languages: [],
      mLanguages: [],
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
  },

  created() {
    let languages = JSON.parse(JSON.stringify(this.locationState.Languages))
    this.mLanguages = languages.map((l) => Number(l.LanguageId))

    this.languages.push({ Id: 52, Name: 'Dutch' })
    this.languages.push({ Id: 65, Name: 'English' })
    this.languages.push({ Id: 75, Name: 'French' })
    this.languages.push({ Id: 86, Name: 'German' })
    this.languages.push({ Id: 106, Name: 'Italian' })
    this.languages.push({ Id: 156, Name: 'Spanish' })
  },

  methods: {
    ...mapMutations('locationStore', ['setLocationState']),

    languageSelected(languageId) {
      return this.mLanguages.findIndex((l) => l === languageId) > -1
    },

    addLanguage(languageId) {
      this.mLanguages.push(languageId)
    },

    removeLanguage(languageId) {
      let index = this.mLanguages.findIndex((l) => l === languageId)
      if (index > -1) {
        this.mLanguages.splice(index, 1)
      }
    },

    saveLocationLanguages() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        let mLocation = JSON.parse(JSON.stringify(self.locationState))

        locationProvider.methods
          .saveLocationLanguages(self.locationState.Id, self.mLanguages)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              self.setLocationState(response.data)

              setTimeout(() => {
                self.onClickCancel()
              }, 1000)
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
